import React from "react";
var d = new Date();
var currYear = d.getFullYear();

function Footer() {
  return (
    <React.StrictMode>
    </React.StrictMode>
  );
}

export default Footer;
